<template>
  <div id="app">
    <TUICallKit style="width: 587px; height: 358px "/>
  </div>
</template>

<script setup>
import {TUICallKit, TUICallKitServer, TUICallType} from "@tencentcloud/call-uikit-vue2";
import axios from "axios"; // Refer to Step 3

// 生成一个随机的6位字符串
function randomString(len) {
  len = len || 32;
  var $chars = 'abcdefhijkmnprstwxyz2345678';
  var maxPos = $chars.length;
  var pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

const userId = randomString(8);

let calleeUserID;

// 页面加载后调用init方法
window.onload = function () {
  init();
};

//【2】Initialize the TUICallKit component
const init = async () => {
  axios.get('https://chenping.oicp.vip/test/getUserSign?userId=' + userId)
      .then(async response => {
        let data = response.data.obj;
        console.log("收到消息：", data)
        await TUICallKitServer.init({
          userID: userId,
          userSig: data.userSign,
          SDKAppID: data.sdkAppId,
        });
        console.log('TUICallKit init succeed');
        // 获取地址栏url信息
        const url = window.location.href;
        console.log(url)
        // 获取后缀的 calleeUserID
        const callUserId = url.split('?')[1];
        if (callUserId) {
          calleeUserID = callUserId.split('=')[1];
        }
        await call();
      })
      .catch(error => {
        console.error('There was an error fetching the users: ', error);
      });
}

const call = async () => {
  await TUICallKitServer.call({
    userID: calleeUserID,
    type: TUICallType.VIDEO_CALL,
  });
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}
</style>
